import { Inject, Injectable } from '@angular/core';
import { Observable, Subject, throwError} from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppConfig {
    public config: Object = null;
    public empresa: Object = null;

    constructor(private http: HttpClient) { }

    public get(key: any) {
        this.load();
        return this.config[key];
    }
    public async getEmpresa(key: any) {
        await this.loadEmpresa();
        try{
            if(this.empresa[key]){
                return this.empresa[key];
            }   
            return false;
        } 
        catch(e:any){
            return false;
        }
        
    }

    public setEmpresa(empresa:any){
        sessionStorage.setItem("empresaSlug",empresa.slug)
        const stylesheets:any = document.styleSheets;
        console.log(stylesheets)
        const colores = stylesheets[2].cssRules[0]  as unknown as HTMLElement;
        colores.style.setProperty('--color-1',empresa.color1,'important');
        colores.style.setProperty('--color-2',empresa.color2,'important');
        colores.style.setProperty('--color-3',empresa.color3,'important');
        colores.style.setProperty('--color-borde-superior',empresa.colorBordeSuperior,'important');
    }

    load() {
        return new Promise((resolve, reject) => {
            this.http.get(environment.configJson)
            .pipe(catchError((error: any): any => {
            //    console.log('Configuración no encontrada');
                resolve(true);
                return Observable.throw(error.json().error || 'Server error');
            }))
            .subscribe((responseData) => {
                this.config = responseData;
                // console.log(this.config);
                resolve(true);
            });
        });
    }
    loadEmpresa() {
        return new Promise((resolve, reject) => {
            this.http.get(environment.empresasJson)
            .pipe(catchError((error: any): any => {
            //    console.log('Configuración no encontrada');
                resolve(true);
                return Observable.throw(error.json().error || 'Server error');
            }))
            .subscribe((responseData) => {
                this.empresa = responseData;
                // console.log(this.config);
                resolve(true);
            });
        });
    }
}
