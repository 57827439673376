<ng-container *ngIf="validEmpresa">
    <ng-container [ngSwitch]="empresa.template">
        <ng-container *ngSwitchCase="'template1-cop'">
            <app-template1-cop  [pac]="pac"
            [bShowLlamado]="bShowLlamado"
            [medico]="medico"
            [piso]="piso"
            [box]="box"
            [paciente]="paciente"
            [bEmpty]="bEmpty"
            [empresa]="empresa"
            ></app-template1-cop>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <app-defaulttemplate  [pac]="pac"
            [bShowLlamado]="bShowLlamado"
            [medico]="medico"
            [piso]="piso"
            [box]="box"
            [paciente]="paciente"
            [bEmpty]="bEmpty"
            [empresa]="empresa"
            ></app-defaulttemplate>
        </ng-container>
    </ng-container>
</ng-container>