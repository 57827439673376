<ng-container >
    <div class="template-1-container-llamado-actual" *ngIf="bShowLlamado">
      <app-llamado [medico]="medico" [piso]="piso" [box]="box" [paciente]="paciente"></app-llamado>
    </div>
    <div class="template-1-container-llamado-actual">
        <div class="fluid llamado-bg template-1-llamado-bg">
            <div class="row  text-center">
                <div class="template-1-logo">
                    <img [src]="empresa.logo_white"  />
                </div>
                <ng-container *ngIf="!bEmpty">
                    <div class="col-12 texto-1 template-1-texto-1 text-morado">
                        Llamando a
                    </div>
                    <div class="col-12 texto-2 template-1-texto-2 text-morado">
                        {{paciente}}
                    </div>
                    <div class="col-12 texto-3 template-1-texto-3 text-morado">
                        <div class="">DR. &nbsp; {{medico}}</div>
                    </div>
                    <div class="col-12 texto-4 template-1-texto-4 text-morado mb-4">
                        Piso {{piso}}
                    </div>
                    <div class="col-12 texto-4 template-1-texto-4 text-morado">
                        Box {{box}}
                    </div>
                </ng-container>
                <ng-container *ngIf="bEmpty">
                    <div class="col-12 texto-1 template-1-texto-1 text-morado">
                        Asignación de turnos en proceso...
                    </div>
                </ng-container>
            </div>
        </div>
    </div>



    <div class="container-fluid template-1-container-llamados">
      <div class="row text-center box-container" *ngIf="bEmpty">
        <div class="col-12 px-0">
            <h1 class="header-title template-1-header-title display-5 mb-2">LLAMADO A CONSULTA</h1>
        </div>
        <div class="col-12 px-0">
            <div class="row d-flex align-items-center color-2 text-white mb-1">
                <div class="col-5 modify-col border-right">
                    <div class="p-1">
                        <h1 class="font-5"> <i class="fas fa-user mr-2 ml-2 fs-45"></i> Paciente</h1>
                    </div>
                </div>

                <div class="col-5 modify-col border-right">
                    <div class="p-1 ">
                        <h1 class="font-5"> <i class="fas fa-user-md mr-2 ml-2 fs-45"></i> Profesional</h1>
                    </div>
                </div>

                <div class="col-2 modify-col ">
                    <div class="p-1 ">
                        <h1 class="font-5"> <i class="fas fa-clipboard-list mr-2 ml-2 fs-45"></i> BOX</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <div class="row text-center box-container" *ngIf="!bEmpty">
            <div class="col-12 px-0">
                <h1 class="header-title template-1-header-title display-5 mb-2">LLAMADO A CONSULTA</h1>
            </div>
            <div class="col-12 px-0">
                <div class="row d-flex align-items-center color-2 text-white mb-1">
                    <div class="col-5 modify-col border-right">
                        <div class="p-1">
                            <h1 class="font-5"> <i class="fas fa-user mr-2 ml-2 fs-45"></i> Paciente</h1>
                        </div>
                    </div>

                    <div class="col-5 modify-col border-right">
                        <div class="p-1 ">
                            <h1 class="font-5"> <i class="fas fa-user-md mr-2 ml-2 fs-45"></i> Profesional</h1>
                        </div>
                    </div>

                    <div class="col-2 modify-col ">
                        <div class="p-1 ">
                            <h1 class="font-5"> <i class="fas fa-clipboard-list mr-2 ml-2 fs-45"></i> BOX</h1>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-12 px-0">
                <div class=" row  d-flex align-items-center text-color-2 bg-white" *ngFor="let paciente of pac" >

                    <div class="col-5 modify-col px-0  border-right border-color-2">
                        <div class="">
                            <h1 class="ml-2 fs-7 ">{{paciente.paciente}}</h1>
                        </div>
                    </div>

                    <div class="col-5 modify-col px-0 border-right border-color-2">
                        <div class="">
                            <h1 class="ml-2 fs-7">{{paciente.medico}}</h1>
                        </div>
                    </div>

                    <div class="col-2 modify-col px-0 border-right border-color-2">
                        <div class="">
                            <h1 class="ml-2 fs-7 text-center">{{paciente.box}}</h1>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 px-0 border-top border-color-2">

                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-container>
