import { Component, OnInit } from '@angular/core';
import { AppConfig } from '../app.config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  logo:any;
  constructor(private config: AppConfig) { }

 async ngOnInit() {
    const empresa = await this.config.getEmpresa(sessionStorage.getItem('empresaSlug'))
    this.logo = empresa.logo
  }



}
