<div class="row">
  
  <div class="col-10 text-left d-flex align-items-center header-title-container" style="padding-left: 50px;">
      <i class="fas fa-user-md display-4 color-icon-1 mr-4"></i> <h1 class="header-title display-5 mb-0">LLAMADO A MÉDICO - PRÓXIMO PACIENTE</h1>
  </div>
  <div class="col-2 text-center">
      <img [src]="logo" style="margin-top:10px;height: 80px; object-fit: contain;width: 220px;" />

  </div>
</div>
